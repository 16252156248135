<template>
  <div class="col-12 px-4">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3">
            <label class="form-item__label info-popup">
              {{$t('platform.settings.inpage.template')}}
               <svg
                v-svg
                v-tooltip="{
                  content: $t('platform.settings.inpage.template_hint'),
                  triggers: ['hover', 'click'],
                }"
                symbol="info"
                size="0 0 18 18"
                role="info"
              />
            </label>
            <custom-multiselect class="multiselect-wrapper multiselect-w-100" v-model="in_page_template" :list="templateOptions" 
             :searchBy="'label'"
              :treeSelect="true"
              :allowEmpty="false">
              <template v-slot:block="props">
                <span v-if="props.value">{{
                  props.value.label || props.value
                }}</span>
              </template>
              <template v-slot:tree-parent="props">
                <span>{{ props.value.label }}</span>
              </template>
              <template v-slot:tree-child="props">
                <span>{{ props.value.label }}</span>
              </template>
            </custom-multiselect>
          </div>
          <div class="col-12 col-lg-6">
            <Counter v-model="delay" :max="60" :placeholder="$t('sec') + '.'" :min="0" :title="'platform.settings.inpage.delay'" :description="'platform.settings.description.inpage.delay'" />
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-item__label info-popup">
              {{ $t("platform.settings.inpage.position") }}

              <svg
                v-svg
                v-tooltip="{
                  content: $t('platform.settings.description.inpage.position'),
                  triggers: ['hover', 'click'],
                }"
                symbol="info"
                size="0 0 18 18"
                role="info"
              />
            </label>
            <custom-multiselect class="multiselect-wrapper multiselect-w-100" v-model="position" :list="positionOptions" :allowEmpty="false">
              <template v-slot:block="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
              <template v-slot:list="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
            </custom-multiselect>
          </div>
       
     
     
          <div class="col-12 col-lg-6 space-for-sm">
            <Counter v-model="delay_after_click" :max="600" :min="0" :placeholder="$t('sec') + '.'" :title="'platform.settings.inpage.pause_after_click'" :description="'platform.settings.description.inpage.pause_after_click'" />
          </div>
          <div class="col-12 col-lg-6">
            <Counter v-model="delay_after_close" :max="600" :placeholder="$t('sec') + '.'" :min="0" :title="'platform.settings.inpage.pause_after_close'" :description="'platform.settings.description.inpage.pause_after_close'" />
          </div>

          <div class="col-12 col-lg-6 space-for-sm">
            <Counter v-model="close_button_delay" :max="30" :min="0" :placeholder="$t('sec') + '.'" :title="'platform.settings.inpage.close_delay'" :description="'platform.settings.inpage.close_delay_hint'" />
          </div>
          <div class="col-12 col-lg-6">
            <Counter v-model="clicks_to_close_count" :max="5" :min="1" :title="'platform.settings.inpage.close_clicks_amount'" :description="'platform.settings.inpage.close_clicks_hint'" />
          </div>
          <div class="col-12 col-lg-6" v-if="featureFlags.includes('auto_refresh_interval')">
            <Counter v-model="auto_refresh_interval" :placeholder="$t('sec') + '.'" :max="120" :min="10" :title="'platform.settings.inpage.autorefresh_interval'" :description="'platform.settings.inpage.autorefresh_hint'" />
          </div>
          <div class="col-12">
             <DelaySettings :delay="dimming" v-model="dimming" :min="0" :max="100" :variable="'%'" :title="'platform.settings.blackout_range'" :description="'platform.settings.description.native_web_push.blackout_settings'" class="pb-2" />
          </div>


          <template v-if="featureFlags.includes('in_page_missclick')">
            <div class="col-12">
            <label class="form-item__label info-popup">
              {{ $t("platform.settings.inpage.sell_missclick_as") }}
            </label>
            <custom-multiselect class="multiselect-wrapper multiselect-w-100" v-model="sell_missclick_as" :list="miss_click_options" :allowEmpty="false">
              <template v-slot:block="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
              <template v-slot:list="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
            </custom-multiselect>
            
            <template v-if="sell_missclick_as.value === 'OnClick'">
            <label class="form-item__label info-popup mt-3">
             {{ $t("platform.settings.pop.pop_type") }}
               <svg
                v-svg
                v-tooltip="{
                  content: $t('platform.settings.description.onclick.pop_type'),
                  triggers: ['hover', 'click'],
                }"
                symbol="info"
                size="0 0 18 18"
                role="info"
              />
            </label>
             <custom-multiselect v-model="open_type" :list="open_type_options" class="multiselect-wrapper form-item__field" :allowEmpty="false">
            <template v-slot:block="props">
              <span>{{ $t(`platform.settings.pop.pop_types.${props.value.title}`) }}</span>
            </template>
            <template v-slot:list="props">
              <span>{{ $t(`platform.settings.pop.pop_types.${props.value.title}`) }}</span>
            </template>
          </custom-multiselect>
          </template>
            </div>
          </template>

          <div class="col-12" v-if="featureFlags.includes('enable-display-settings')">
            <DisplaySettings :displaySettings="displaySettings" :disableOsVersions="osVersions" />
          </div>
        </div>
        
        <button @click.prevent="sendRequest" class="btn my-3 _mob100">
          {{ $t("save") }}
        </button>
        <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green my-3 ml-2 _mob100"><svg v-svg size="0 0 20 13" symbol="code"></svg> {{ $t("platforms.table.get_code") }}</button>
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="banner-wrapper">
           <img v-if="loading"
           width="64px" height="64px" :src="require('@/assets/images/rolling.svg')" alt="loader" class="loader" />
           <img v-else :src="previewImage" class="preview" 
            :class="[position.value, in_page_template.label.toLowerCase()+'-temp']" />
          <div class="screen__opacity" :style="`opacity: ${dimming / 100}`"></div>
          <img src="@/assets/images/in-page-banner.svg" class="banner" />
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="platform" :showSettingsLink="false" @closeModal="closeModal" />
    </modal>
  </div>
</template>

<script>
import Counter from "../Counter.vue";
import DelaySettings from "../DelaySettings.vue";
import DisplaySettings from "../DisplaySettings.vue";
import siteSettingsService from "@/api/siteSettingsService";
import { mapGetters } from "vuex";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";

export default {
  name: "InPage",
  props: {
    platform: {
      type: Object,
    },
  },
  components: {
    Counter,
    DelaySettings,
    DisplaySettings,
  },
  mixins: [platformSettingsGetCode],
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
    previewImage(){
      return this.imageMap[this.in_page_template.label] || null
    }
  },
  data() {
    return {
      loading: true,
      imageMap: {
        'Default':require('@/assets/images/previews/main.png'),
        'Mac':require('@/assets/images/previews/mac.png'),
        'Windows':require('@/assets/images/previews/windows.png'),
        'Android':require('@/assets/images/previews/android.png'),
        'iOS':require('@/assets/images/previews/iOS.png'),
        'Black':require('@/assets/images/previews/black.png'),
        'White':require('@/assets/images/previews/white.png'),
        'Pink(Dating)':require('@/assets/images/previews/pink-dating.png'),
        'White(Online)':require('@/assets/images/previews/white-online.png'),
        'Facebook':require('@/assets/images/previews/facebook.png'),
        'Instagram':require('@/assets/images/previews/instagram.png'),
        'Telegram':require('@/assets/images/previews/telegram.png'),
        'Twitter':require('@/assets/images/previews/twitter.png'),
        'Whatsapp':require('@/assets/images/previews/whatsapp.png'),
        'Small':require('@/assets/images/previews/small.png'),
        'Dialog':require('@/assets/images/previews/dialog.png'),
        'Compact':require('@/assets/images/previews/compact.png'),
        'Static display':require('@/assets/images/previews/static.png'),
        'Light':require('@/assets/images/previews/light.png'),
        'Dark':require('@/assets/images/previews/dark.png'),
        'IOS Dark':require('@/assets/images/previews/ios-dark.png'),
        'Large':require('@/assets/images/previews/large.png'),
        'Large 1':require('@/assets/images/previews/large-1.png'),
        'Large 2':require('@/assets/images/previews/large-2.png'),
        'Large 3':require('@/assets/images/previews/large-3.png'),
        'Deep':require('@/assets/images/previews/deep.png'),
        'Frame':require('@/assets/images/previews/frame.png'),
        'Grand':require('@/assets/images/previews/grand.png'),
        'Twist Notification':require('@/assets/images/previews/twist-notification.gif'),
        'Spin Animation':require('@/assets/images/previews/spin-animation.gif'),
        'Ripple effect':require('@/assets/images/previews/ripple-effect.gif'),
        'Ripple effect 2':require('@/assets/images/previews/ripple-effect2.gif'),
        'Purple gradient':require('@/assets/images/previews/purple-gradient.gif'),
        'Pure white':require('@/assets/images/previews/pure-white.gif'),
        'Float Motion':require('@/assets/images/previews/float-motion.gif'),
      }, 
      delay: 0,
      dimming: 0,
      close_button_delay: 0,
      clicks_to_close_count: 1,
      auto_refresh_interval: 30,
      position: { id: 4, value: "bottom-right" },
      templateOptions: [
        {
          id: 0,
          label: "Default",
        },
        {
          id: -1,
          label: "InPage Push",
          children: [
            {id: -1, label: "OS", groupValue: true},
            {id: 1, label: "Mac"},
            {id: 2, label: "Windows"},
            {id: 3, label: "Android"},
            {id: 4, label: "iOS"},
            {id: -1, label: "Custom", groupValue: true},
            {id: 5, label: "Black"},
            {id: 6, label: "White"},
            {id: 7, label: "Pink(Dating)"},
            {id: 8, label: "White(Online)"},
            {id: -1, label: "Social", groupValue: true},
            {id: 9, label: "Facebook"},
            {id: 10, label: "Instagram"},
            {id: 11, label: "Telegram"},
            {id: 12, label: "Twitter"},
            {id: 13, label: "Whatsapp"},
            {id: -1, label: "Classic", groupValue: true},
            {id: 14, label: "Small"},
            {id: 15, label: "Dialog"},
            {id: 16, label: "Compact"},
            {id: 17, label: "Frame"},
          ],
        },
        {
          id: -1,
          label: "Alert Icons",
          children: [
            {id: -1, label: "Basic bubble", groupValue: true},
            {id: 18, label: "Ripple effect"},
            {id: 19, label: "Ripple effect 2"},
            {id: 20, label: "Static display"},
            {id: -1, label: "Text Pulse", groupValue: true},
            {id: 21, label: "Spin Animation"},
            {id: 22, label: "Twist Notification"},
            {id: 23, label: "Dynamic Message", groupValue: true},
            {id: 24, label: "Pure white"},
            {id: 25, label: "Float Motion"},
            {id: 26, label: "Purple gradient"},
          ],
        },
        {
          id: -1,
          label: "Custom widgets",
          children: [
            {id: -1, label: "Flat style", groupValue: true},
            {id: 27, label: "Light"},
            {id: 28, label: "Dark"},
            {id: -1, label: "Retro", groupValue: true},
            {id: 29, label: "IOS Dark"},
            {id: 30, label: "Large"},
            {id: 31, label: "Large 1"},
            {id: 32, label: "Large 2"},
            {id: 33, label: "Large 3"},
            {id: 34, label: "Deep"},
            {id: 35, label: "Grand"},
          ],
        },
      ],
      in_page_template: {id: 0, label: "Default"},
      delay_after_click: 10,
      delay_after_close: 10,
      positionOptions: [
        { id: 1, value: "top-left" },
        { id: 2, value: "top-right" },
        { id: 3, value: "bottom-left" },
        { id: 4, value: "bottom-right" },
        { id: 5, value: "center" },
      ],
      sell_missclick_as:{value: "OnClick" },
      miss_click_options: [
        { value: "OnClick" },
        { value: "InPage" },
      ],
      open_type: { id: 1, title: "new_tab" },
      open_type_options: [
        { id: 1, title: "new_tab" },
        { id: 2, title: "new_tab_left" },
        { id: 4, title: "replace" },
        { id: 5, title: "popup" },
      ],
      displaySettings: {
        disableDeviceSell: [],
        disableOsSell: [],
        notShowReff: false,
        disableYandex: false,
      },
      osVersions: [],
    };
  },

  mounted() {
    const id = this.$route.params.id;

    siteSettingsService
      .getOsVersions()
      .then((res) => {
        if (res && res.status === 200) {
          this.osVersions = res.data;
          return siteSettingsService.getInPage(id);
        }
      })
      .then((resp) => {
        if (resp.data.length === 0) {
          console.log("No data");
        } else {
          this.delay = resp.data.delay;
          this.position.value = resp.data.position;
          this.delay_after_click = resp.data.delay_after_click;
          this.delay_after_close = resp.data.delay_after_close;
          this.auto_refresh_interval = resp.data.auto_refresh_interval || 30;
          this.open_type = this.setPopType(resp.data.missclick?.open_type);
          this.sell_missclick_as.value = resp.data.missclick?.sell_as || 'OnClick';

          this.in_page_template = 
          this.findOptionByLabel(this.templateOptions,resp.data.in_page_template)
          || {id: 0, label: "Default"};
          this.dimming = resp.data.dimming;
          this.close_button_delay = resp.data.close_button_delay;
          this.clicks_to_close_count = resp.data.clicks_to_close_count || 1;

          this.displaySettings = {
            disableDeviceSell: resp.data.display_settings?.disable_devices || [],
            disableOsSell: this.osVersions.filter((el) => resp.data.display_settings?.disable_os.includes(el.value)) || [],

            notShowReff: resp.data.display_settings?.disable_no_referer || false,
            disableYandex: resp.data.display_settings?.disable_yandex_browser || false,
          };
        }
        this.loading = false
      });
  },
  methods: {
    setPopType(type) {
      const defaultOption = { id: 1, title: "new_tab" };
      const selectedOption = this.open_type_options.find((option) => option.title === type);
      return selectedOption || defaultOption;
    },
    sendRequest() {
      const id = this.$route.params.id;
      const payload = {
        delay: this.delay,
        position: this.position.value,
        delay_after_click: this.delay_after_click,
        delay_after_close: this.delay_after_close,
        auto_refresh_interval: this.featureFlags.includes('auto_refresh_interval') ? 
        this.auto_refresh_interval: undefined,
        missclick:  this.featureFlags.includes("in_page_missclick")
          ? {
          sell_as: this.sell_missclick_as.value,
          open_type: this.sell_missclick_as.value === 'OnClick' ? this.open_type.title: undefined,
        } : undefined,
        

        in_page_template: this.in_page_template.label,
        dimming: this.dimming,
        close_button_delay: this.close_button_delay,
        clicks_to_close_count: this.clicks_to_close_count,

        display_settings: this.featureFlags.includes("enable-display-settings")
          ? {
              disable_devices: this.displaySettings.disableDeviceSell || [],
              disable_os: this.displaySettings.disableOsSell.map((el) => el.value) || [],
              disable_no_referer: this.displaySettings.notShowReff,
              disable_yandex_browser: this.displaySettings.disableYandex,
            }
          : undefined,
      };

      siteSettingsService.setInPage(id, payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if (res && res.status === 500) {
          this.$alert({
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: `${this.$t("save_successfully")}`,
          });
          this.allowButton = false;
        }
      });
    },
  findOptionByLabel(options, label){
  for (const option of options) {
    if (option.label === label) {
      return { id: option.id, label: option.label };
    }
    if (option.children) {
      const found = this.findOptionByLabel(option.children, label);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
  }
}
}
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

.col-12, .col-lg-6 {
  padding: 0 10px;
}

img.loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto !important;
}

.screen__opacity {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
}

@media screen and (max-width: 991px) {
  .space-for-sm {
    padding-top: 20px;
  }
}

.banner-wrapper {
  position: relative;
  max-width: 530px;
  margin: 0 auto;
 @media screen and (max-width: 990px) {
  margin-bottom: 40px;
  margin-top: 20px;
 }
}
img.banner {
  width: 100%;
  height:auto;
}
img.preview {
  position: absolute;
  z-index:3;
  max-width: 60%;
  height: auto;
  margin: initial;
  left: initial;
  right: initial;
  padding: 0;
  
  &.ripple, &.static.display-temp {
    max-width: 30%;
  }
  &.twist.notification-temp, &.pure.white-temp, &.float.motion-temp {
    margin-right: -15px;
  }
&.center {
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto !important;
}
&.top-left {
  left: 15px;
  top: 50px;
}
&.top-right {
  top: 50px;
  right: 15px;
}
&.bottom-left {
  bottom: 10px;
  left: 15px;
}
&.bottom-right {
  bottom:10px;
  right: 15px;
}
}

.multiselect-wrapper {
  ::v-deep {
    .multiselect__content-wrapper {
      @media (min-width: 768px) {
        width: max-content;
        max-width: max-content;
      }
      @media (max-width: 768px) {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
      }
    }
    .multiselect__tags {
      .multiselect__single {
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 100%;
          background: #f5f5f5;
          right: -10px;
          box-shadow: -5px 0px 20px 20px #f5f5f5;
        }
      }
    }
  }
}
.multiselect-w-100 {
  ::v-deep {
    .multiselect__content-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
